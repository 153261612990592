<template>
  <div>
    <bread-crumb
      :crumbData="['角色管理', '新增']"
      :chooseCrumbData="2"
      @close="onClose"
    ></bread-crumb>

    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
      style="width: 80%; padding: 50px"
    >
      <el-form-item label="名称：" prop="rule_name">
        <el-input v-model="ruleForm.rule_name"></el-input>
      </el-form-item>
      <el-form-item label="描述：">
        <el-input v-model="ruleForm.rule_description"></el-input>
      </el-form-item>
      <el-form-item label="说明：">
        <el-input v-model="ruleForm.rule_explain"></el-input>
      </el-form-item>
      <el-form-item
        :label="item.rule_name + '：'"
        v-for="item in ruleDate"
        :key="item.id"
      >
        <el-checkbox-group v-model="arr">
          <el-checkbox
            v-for="item1 in item.children"
            :key="item1.id"
            :label="item1.id"
            @change="checkChange(item1)"
            >{{ item1.rule_name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      ruleForm: {
        rule_name: "",
        rule_description: "",
        rule_explain: "",
        rules: [],
        commercial_id: "",
        id: 0,
      },
      rules: {
        rule_name: [{ required: true, message: "必填字段", trigger: "blur" }],
      },
      ruleDate: [],
      arr: [],
    };
  },
  async mounted() {
    this.ruleForm.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    const res = await this.$http.post("/system/auth_group/authList");
    this.ruleDate = res.data;
    if (this.$props.data) {
      console.log(this.$props.data);
      this.ruleForm = this.$props.data;
      this.arr = this.ruleForm.rules.map((item) => {
        return item.id;
      });
    }
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },

    submitForm(formName) {
      console.log(this.ruleForm.rules);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/system/auth_group/save", this.ruleForm)
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.onClose(0);
              } else {
                this.$message.error(res.data.msg);
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    checkChange(obj) {
      let isPush = true;
      for (let i = 0; i < this.ruleForm.rules.length; i++) {
        if (this.ruleForm.rules[i].id == obj.id) {
          this.ruleForm.rules.splice(i, 1);
          isPush = false;
          break;
        }
      }
      if (isPush) {
        this.ruleForm.rules.push(obj);
      }
    },
  },
};
</script>
<style scoped></style>
