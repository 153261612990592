<template>
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['角色管理']" :chooseCrumbData="1"></bread-crumb>
      <search-case
        :searchData="searchData"
        @inquireBtnClick="inquireBtnClick"
      ></search-case>

      <div
        style="
          padding: 10px 20px;
          border-bottom: 1px solid #eee;
          display: flex;
          align-items: center;
        "
      >
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="isShow = 1">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">新增角色</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini" @click="batchClick">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">批量删除</span>
            </div>
          </el-button>
        </div>
      </div>

      <div style="padding: 20px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          border
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="44"> </el-table-column>
          <el-table-column prop="id" sortable label="ID"></el-table-column>
          <el-table-column
            prop="create_time"
            sortable
            label="创建日期"
          ></el-table-column>
          <el-table-column
            prop="update_time"
            sortable
            label="修改日期"
          ></el-table-column>
          <el-table-column prop="rule_name" label="名称"></el-table-column>
          <el-table-column
            prop="rule_description"
            label="描述"
          ></el-table-column>
          <el-table-column label="是否内置">
            <div>否</div>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete poiner"
                @click="handleDelete(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style=""
            >显示第&nbsp;{{
              tableData.length > 0 ? (page - 1) * length + 1 : "0"
            }}&nbsp;至&nbsp;{{
              (page - 1) * length + tableData.length
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >

          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button @click="homePage">首页</el-button>
            <el-button @click="upPage">上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              @current-change="currentChange"
              :total="tableFinshNum"
              :current-page="page"
            ></el-pagination>
            <el-button @click="nextPage">下页</el-button>
            <el-button @click="endPage">末页</el-button>
          </div>
        </div>
      </div>
    </div>

    <role-add v-if="isShow == 1" @close="onClose" :data="data"></role-add>
  </div>
</template>
<script>
import roleAdd from "./childrens/role_add.vue";
import { getTime } from "../../../../until/getTime";
export default {
  components: {
    roleAdd,
  },
  data() {
    return {
      isShow: 0,

      searchData: {
        date: "",
        QueryContent: "",
        inquirePlaceholder: "输入要查询的内容",
        isInquireValue: true,
        isInquireBtn: true,
      },

      tableData: [],
      multipleSelection: [],
      tableFinshNum: 0,
      page: 1,
      length: 10,
      commercial_id: "",
      data: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
  },
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async getTabList() {
      const res = await this.$http.post("/system/auth_group/lst", {
        currentPage: this.page,
        currentLength: this.length,
        commercial_id: this.commercial_id,
      });
      this.tableData = res.data.data;
      this.tableFinshNum = res.data.count;
    },
    handleEdit(row) {
      this.data = row;
      this.isShow = 1;
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/system/auth_group/del", {
              id: row.id,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    batchClick() {
      if (this.multipleSelection.length > 0) {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const idArr = this.multipleSelection.map((item) => {
              return item.id;
            });
            this.$http
              .post("/system/auth_group/bacthDel", {
                idArr,
              })
              .then((res) => {
                if (res.data.status == "success") {
                  this.$message({
                    type: "success",
                    message: res.data.msg,
                  });
                  this.getTabList();
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    inquireBtnClick(data) {
      this.$http
        .post("/system/auth_group/search", {
          firstTime: getTime(data.dateRange[0]),
          lastTime: getTime(data.dateRange[1]),
          search: data.inquireValue,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    onClose(data) {
      this.isShow = data;
      this.getTabList();
      this.data = "";
    },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page == 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page += 1;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
  },
  watch: {
    page: {
      handler() {
        this.getTabList();
      },
    },
  },
};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .cell {
  padding: 0 !important;
}
</style>
